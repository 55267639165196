<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap border-bottom py-2">
        <VocabularyTitle :title="$t('sideBar.advertisers')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="selectedAgency"
              label="name"
              track-by="id"
              :options="filterAgenciesList"
              class="w-300px"
              :placeholder="$t('table.selectAgency')"
              @input="pageClick(1)"
            ></MultiSelect>
            <MultiSelect
              v-model="selectedBrand"
              label="name"
              track-by="id"
              :options="filterBrandsList"
              class="w-300px"
              :placeholder="$t('table.selectGroup')"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="vocabulary-table">
        <div class="table-header sticky-vocabulary-header">
          <div class="table-header-text justify-content-start" style="flex-basis: 70%">
            <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
            {{ $t('sideBar.advertisers') }}
          </div>
          <div class="table-header-text justify-content-start" style="flex-basis: 30%">
            {{ $t('table.agency') }}
          </div>
        </div>

        <SpinnerLoader :loading="advertisersStatus" />

        <DropDownTable
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :child-item="row.brands"
          edit-permission="advertiser.update"
          add-permission="brand.create"
          delete-permission="advertiser.delete"
          edit-inner-permission="brand.update"
          delete-inner-permission="brand.delete"
          @showModalAddChild="showModalAddBrand"
          @showModalEditChild="showModalEditBrand"
          @showModalDeleteChild="showModalDeleteBrand"
          @showModalEditParent="showModalAdvertisers"
          @showModalDeleteParent="showModalDeleteAdvertisers"
        />

        <div v-if="advertisersStatus === 'success' && tableData && tableData.length === 0" class="mt-2 d-flex justify-content-center">
          {{ $t('table.noContent') }}
        </div>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('advertiser.create')" variant="primary" @click="showModalAdvertisers()">
          {{ $t('table.addAdvertisers') }}
        </b-button>
      </div>
      <!--FOOTER-->
    </div>

    <!--MODAL ADD\EDIT ADV-->
    <b-modal
      id="add-edit-advertisers"
      ref="add-edit-advertisers"
      size="lg"
      :title="modalAdvertiser.type === 'add' ? $t('table.addNewAdvertisers') : $t('table.editAdvertisers')"
      :ok-disabled="$v.modalAdvertiser.$invalid"
      :ok-title="modalAdvertiser.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidAdvThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAdvThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-name-adv">
          <b-form-input id="input-name-adv" v-model.trim="modalAdvertiser.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('table.selectAgency')">
          <CoupleSelects
            :add-element.sync="modalAdvertiser.selectAdd"
            :delete-element.sync="modalAdvertiser.selectDelete"
            :add-options="modalAdvertiser.options"
            :remove-options="modalAdvertiser.value"
            @leftHandler="leftHandler()"
            @rightHandler="rightHandler()"
          />
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD\EDIT ADV-->

    <!--MODAL ADD\EDIT GROUP-->
    <b-modal
      id="add-edit-brand"
      ref="add-edit-brand"
      size="lg"
      :title="modalBrand.type === 'add' ? $t('table.addBrand') : $t('table.editBrand')"
      :ok-disabled="$v.modalBrand.$invalid"
      :ok-title="modalBrand.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidGroupThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidGroupThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-group-name">
          <b-form-input id="input-group-name" v-model.trim="modalBrand.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('table.selectAdvertiser')">
          <MultiSelect
            v-model="modalBrand.advertiser"
            label="name"
            track-by="id"
            :options="modalBrand.advertisersList"
            :placeholder="$t('table.selectAdvertiser')"
          ></MultiSelect>
        </b-form-group>

        <div class="d-flex gap-3">
          <b-form-group :label="$t('table.selectClass')" class="w-50">
            <MultiSelect
              v-model="modalBrand.class"
              label="name"
              track-by="id"
              :options="classes"
              :placeholder="$t('table.selectClass')"
              data-automatic="ms-class"
              required
              @input="loadGroupsList()"
            ></MultiSelect>
          </b-form-group>
          <b-form-group :label="$t('table.selectGroups')" class="w-50">
            <MultiSelect
              v-model="modalBrand.group"
              label="name"
              track-by="id"
              :options="modalBrand.groupList"
              :placeholder="$t('table.selectGroups')"
              data-automatic="ms-group"
              required
            ></MultiSelect>
          </b-form-group>
        </div>
      </form>
    </b-modal>
    <!--MODAL ADD\EDIT GROUP-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import DropDownTable from '../components/DropDownTable';
import errorsHandler from '@/utils/errorsHandler';
import MultiSelect from '../components/MultiSelect';
import PaginateWrapper from '../components/PaginateWrapper';
import SortArrows from '../components/SortArrows';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import CoupleSelects from '@/components/CoupleSelects.vue';
import { getAdvertisers } from '@/api/apiServices';

export default {
  name: 'Advertisers',
  components: { PaginateWrapper, MultiSelect, DropDownTable, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, CoupleSelects },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    agency_id: { type: [String, Number], default: undefined },
    brand_id: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      modalAdvertiser: {
        type: 'add',
        name: '',
        value: [],
        options: [],
        selectAdd: '',
        selectDelete: '',
      },
      modalBrand: {
        type: 'add',
        name: '',
        advertiser: '',
        advertisersList: [],
        class: '',
        group: '',
        groupList: [],
      },

      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      selectedAgency: '',
      selectedBrand: '',
      filterAgenciesList: [],
      filterBrandsList: [],

      isModalBusy: false,
      isModalLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      advertisers: 'getAdvertisers',
      advertiser: 'getAdvertiser',
      advertisersStatus: 'getAdvertisersStatus',
      agencies: 'getAgenciesList',
      brands: 'getBrands',
      classes: 'getClassesList',
      group: 'getGroup',
      modalEditAdvertisers: 'getModalEditAdvertisers',
      modalEditClasses: 'getModalEditClasses',
      brandsEdit: 'getBrandsEdit',
      brandsListWithAll: 'getBrandsListWithAll',
    }),
  },
  validations: {
    modalAdvertiser: {
      name: { required },
    },
    modalBrand: {
      name: { required },
      class: { required },
      group: { required },
      advertiser: { required },
    },
  },
  watch: {
    advertisers() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.advertisers') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getAdvertisersInfo();
  },
  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearClassesList');
    this.$store.commit('clearAgenciesList');
    this.$store.commit('clearBrandsList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_ADVERTISERS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[agency_id]': this.selectedAgency ? this.selectedAgency.id : '',
        'filter[brands.id]': this.selectedBrand?.id,
        include: 'brands,agencies',
        //"fields[agencies]": "id,name",
        'fields[brands]': 'advertiser_id,brand_group_id,id,name',
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.advertisers) {
        this.tableData = this.advertisers.data;
        this.paginationData = this.advertisers.pagination;
        if (this.filterPage > this.advertisers.pagination.last_page) {
          this.filterPage = this.advertisers.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getAdvertisersInfo() {
      await Promise.all([this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }), this.$store.dispatch('GET_BRANDS', { per_page: 2000 })]);
      this.filterAgenciesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.agencies];
      this.filterBrandsList = this.brandsListWithAll;
      await this.restoreFromQuery('advertisers');
      this.paramsData();
    },

    clearAdvertiserField() {
      this.modalAdvertiser.type = 'add';
      this.modalAdvertiser.name = '';
      this.modalAdvertiser.value = [];
      this.modalAdvertiser.options = [];
      this.modalAdvertiser.selectAdd = '';
      this.modalAdvertiser.selectDelete = '';
    },

    clearBrandFields() {
      this.modalBrand.type = 'add';
      this.modalBrand.name = '';
      this.modalBrand.advertiser = '';
      this.modalBrand.advertisersList = [];
      this.modalBrand.class = '';
      this.modalBrand.group = '';
      this.modalBrand.groupList = [];
    },

    leftHandler() {
      if (this.modalAdvertiser.selectAdd?.id) {
        this.modalAdvertiser.options = this.modalAdvertiser.options.filter((option) => option.id !== this.modalAdvertiser.selectAdd.id);
        this.modalAdvertiser.value.unshift(this.modalAdvertiser.selectAdd);
        this.modalAdvertiser.selectAdd = '';
      }
    },
    rightHandler() {
      if (this.modalAdvertiser.selectDelete?.id) {
        this.modalAdvertiser.value = this.modalAdvertiser.value.filter((option) => option.id !== this.modalAdvertiser.selectDelete.id);
        this.modalAdvertiser.options.unshift(this.modalAdvertiser.selectDelete);
        this.modalAdvertiser.selectDelete = '';
      }
    },

    async showModalAdvertisers(data) {
      this.isModalLoading = true;
      this.clearAdvertiserField();
      data ? (this.modalAdvertiser.type = 'edit') : (this.modalAdvertiser.type = 'add');
      this.$refs['add-edit-advertisers'].show();
      if (!this.agencies || this.agencies.length < 1) await this.$store.dispatch('GET_AGENCIES', { per_page: 1000 });
      this.modalAdvertiser.options = this.agencies;
      if (data) {
        await this.$store.dispatch('GET_ADVERTISERS_ID', data.data);
        if (this.modalEditAdvertisers) {
          this.modalAdvertiser.name = this.modalEditAdvertisers.name;
          this.modalAdvertiser.value = this.modalEditAdvertisers.agencies;
          const ids = this.modalAdvertiser.value.map((el) => el.id);
          this.modalAdvertiser.options = this.agencies.filter((option) => !ids.includes(option.id));
        }
      }
      this.isModalLoading = false;
    },

    async addEditAdvertisers() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalAdvertiser.type === 'add' ? undefined : this.modalEditAdvertisers?.id,
        name: this.modalAdvertiser.name,
        agencies: this.modalAdvertiser.value.map((v) => v.id),
      };
      await this.$store.dispatch(this.modalAdvertiser.type === 'add' ? 'POST_ADVERTISERS' : 'PUT_ADVERTISERS_ID', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.modalAdvertiser.type === 'add' ? this.$i18n.t('alert.addAdvertisers') : this.$i18n.t('alert.editAdvertisers'),
            text: this.modalAdvertiser.name,
          });
          this.$refs['add-edit-advertisers'].hide();
          this.paramsData();
          this.clearAdvertiserField();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    async showModalDeleteAdvertisers(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteAdvertisers')} "${data?.data?.name}"?`, {
          title: this.$i18n.t('table.deleteAdvertisers'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-adv-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.data?.id;
            const name = data?.data?.name;
            this.$store.dispatch('DELETE_ADVERTISERS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteAdvertisers'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    async loadAdvertisersListForBrandModal() {
      await getAdvertisers({ per_page: 1500 })
        .then((res) => (this.modalBrand.advertisersList = res.data?.data || []))
        .catch((e) => {});
    },

    async showModalAddBrand(data) {
      this.isModalLoading = true;
      this.clearBrandFields();
      this.modalBrand.type = 'add';
      this.$refs['add-edit-brand'].show();
      await Promise.all([
        !this.classes || this.classes.length < 1 ? this.$store.dispatch('GET_CLASSES', { per_page: 1000 }) : undefined,
        this.loadAdvertisersListForBrandModal(),
      ]);
      this.modalBrand.advertiser = { id: data.id, name: data.name };
      this.isModalLoading = false;
    },

    async showModalEditBrand(data) {
      this.isModalLoading = true;
      this.clearBrandFields();
      this.modalBrand.type = 'edit';
      this.$refs['add-edit-brand'].show();
      await Promise.all([
        this.$store.dispatch('GET_BRANDS_ID', data.id),
        !this.classes || this.classes.length < 1 ? this.$store.dispatch('GET_CLASSES', { per_page: 1000 }) : undefined,
        this.loadAdvertisersListForBrandModal(),
      ]);
      if (this.brandsEdit?.data) {
        this.modalBrand.name = this.brandsEdit.data?.name;
        this.modalBrand.advertiser = this.brandsEdit.data?.advertiser || '';
        this.modalBrand.class = this.brandsEdit.data?.brand_group?.brand_class || '';
        await this.loadGroupsList();
        this.modalBrand.group = this.brandsEdit.data?.brand_group || '';
      }
      this.isModalLoading = false;
    },

    async addEditBrand() {
      this.isModalBusy = true;
      const formData = {
        brand_classes_id: this.modalBrand.class?.id,
        brand_group_id: this.modalBrand.group?.id,
        advertiser_id: this.modalBrand.advertiser?.id,
        id: this.modalBrand.type === 'add' ? undefined : this.brandsEdit?.data?.id,
        name: this.modalBrand.name,
      };
      await this.$store.dispatch(this.modalBrand.type === 'add' ? 'POST_BRANDS' : 'PUT_BRANDS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalBrand.type === 'add' ? this.$i18n.t('alert.addBrand') : this.$i18n.t('alert.editBrand'),
            text: this.modalBrand.name,
          });
          this.$refs['add-edit-brand'].hide();
          this.paramsData();
          this.clearBrandFields();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    async loadGroupsList() {
      if (this.modalBrand.class) {
        await this.$store.dispatch('GET_CLASSES_ID', this.modalBrand.class.id);
        this.modalBrand.groupList = this.modalEditClasses?.brand_groups || [];
        if (this.modalBrand.group) this.modalBrand.group = '';
      }
    },

    async showModalDeleteBrand(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteBrand')} "${data?.name}"?`, {
          title: this.$i18n.t('table.deleteBrand'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-brand-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.id;
            const name = data?.name;
            this.$store.dispatch('DELETE_BRANDS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteBrand'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidAdvThenEnter() {
      if (!this.$v.modalAdvertiser.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalAdvertiser.type === 'add'
              ? `${this.$i18n.t('table.confirmAddAdvertisers')} "${this.modalAdvertiser.name}"?`
              : `${this.$i18n.t('table.confirmEditAdvertisers')} "${this.modalAdvertiser.name}"?`,
            {
              title: this.modalAdvertiser.type === 'add' ? this.$i18n.t('table.addNewAdvertisers') : this.$i18n.t('table.editAdvertisers'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-adv-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditAdvertisers();
          })
          .catch((err) => {});
      }
    },

    checkIfValidGroupThenEnter() {
      if (!this.$v.modalBrand.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalBrand.type === 'add'
              ? `${this.$i18n.t('table.confirmAddBrand')} "${this.modalBrand.name}"?`
              : `${this.$i18n.t('table.confirmEditBrand')} "${this.modalBrand.name}"?`,
            {
              title: this.modalBrand.type === 'add' ? this.$i18n.t('table.addBrand') : this.$i18n.t('table.editBrand'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-brand-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditBrand();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="scss"></style>
